import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../reducers'
import { selectMatchStatus } from '../../selectors/match-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import Util from '../../util/util'
import {
  DEFAULT_PLAYLIST_KEY,
  defaultMatchInfo,
  defaultPlaylistInfo,
} from '../../constants/constants'
import { clone } from 'ramda'
import { addOrUpdateMatchInfo } from '../social/leaderboard-actions'
import {
  selectOwnedPlaylistMap,
  selectUserTracksInfo,
} from '../../selectors/blaster-peer-selectors'
import { PlaylistInfo } from '../../types'
import { addTrackToLocalPlaylist } from './update-local-track'

export const duplicateMatch = createAsyncThunk<
  void,
  { matchOwner: string; matchSlug: string },
  { state: RootState; dispatch: AppDispatch }
>('duplicateMatch', ({ matchOwner, matchSlug }, { dispatch, getState }) => {
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const { info: matchInfo } = selectMatchStatus(matchOwner, matchSlug)(state)
  const newMatchSlug = Util.generateId()
  const { title, playlists, playlistOrder } = matchInfo
  const newMatchInfo = {
    ...defaultMatchInfo(),
    title: `Copy of ${title}`,
    slug: `${currentUsername}/${newMatchSlug}`,
    playlistOrder: [...playlistOrder],
    playlists: clone(playlists),
  }
  dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo })).then(() => {
    if (matchOwner !== currentUsername) {
      const matchOwnerTracks = selectUserTracksInfo(matchOwner)(state)
      const matchTrackSlugs: string[] = []
      Object.values(playlists).forEach(({ trackOrder }) => {
        trackOrder.forEach((trackSlug) => {
          matchTrackSlugs.push(trackSlug)
        })
      })
      const ownedPlaylistMap = selectOwnedPlaylistMap(currentUsername)(state)
      const { trackOrder: userAllTracks = [] } = ownedPlaylistMap[DEFAULT_PLAYLIST_KEY]
      const newPlaylistDef: PlaylistInfo = {
        ...defaultPlaylistInfo,
        slug: Util.generateId(),
        title: `tracks from ${title}`,
        trackOrder: matchTrackSlugs,
      }
      const trackSlugsToAdd = matchTrackSlugs.filter(
        (trackSlug) => !userAllTracks.includes(trackSlug)
      )
      const tracksToAdd = trackSlugsToAdd.map((trackSlug) => matchOwnerTracks[trackSlug])
      dispatch(addTrackToLocalPlaylist({ playlist: newPlaylistDef, tracksToAdd }))
    }
  })
})
