import { createAsyncThunk } from '@reduxjs/toolkit'

import blasterPeersSlice from '../../reducers/blasterPeersSlice'
import matchStatusSlice from '../../reducers/matchStatusSlice'
import { selectBlasterPeer } from '../../selectors/blaster-peer-selectors'
import { selectMatchInfo } from '../../selectors/match-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import realtime from '../../services/RealtimeService'
import { getSummaryTitle } from '../../util/track-utils'
import { AppDispatch, RootState } from '../../reducers'
import { PlaylistInfo, TrackInfo } from '../../types'
import { defaultTrackInfo } from '../../constants/constants'

const initPlaylist = createAsyncThunk<
  void,
  { username: string; playlistDef: PlaylistInfo },
  { state: RootState; dispatch: AppDispatch }
>('initPlaylist', ({ username, playlistDef }, { dispatch, getState }) => {
  const { slug, title, wordCount, trackOrder = [] } = playlistDef
  const state = getState()
  const blasterPeer = selectBlasterPeer(username)(state)
  const {
    tracks: currentTracks,
    allTracksPlaylistSlug,
    isCurrentUser,
    ownedMatchSlug,
  } = blasterPeer
  const isOwnedAllTracksPlaylist = isCurrentUser && slug === allTracksPlaylistSlug
  /*
   * dealing with the potentially flawed correspondence between
   * a playlists's trackOrder slugs and the current list of tracks associated with this peer...
   */
  let needsRealtimeSync = false
  const orphanedTrackSlugs = trackOrder.filter((trackSlug: string) => !(trackSlug in currentTracks))
  if (orphanedTrackSlugs.length) {
    console.log('orphaned track slugs', orphanedTrackSlugs)
    needsRealtimeSync = true
  }
  // filter out any that are missing, cuz what else can we do
  const fixedUpTrackOrder = trackOrder
    .filter((trackSlug: string) => trackSlug in currentTracks)
    .map((trackSlug: string) => trackSlug)

  if (isOwnedAllTracksPlaylist) {
    const allTrackSlugs = Object.keys(currentTracks)
    const danglingTracks = allTrackSlugs.filter(
      (trackSlug) => !fixedUpTrackOrder.includes(trackSlug)
    )
    if (danglingTracks.length) {
      console.log('missing from all tracks', danglingTracks)
      needsRealtimeSync = true
    }
    fixedUpTrackOrder.push(...danglingTracks)
  }
  const updatedWordCount = fixedUpTrackOrder.reduce(
    (playlistWordCount: number, trackSlug: string) => {
      const { wordCount: trackWordCount = 0 } = currentTracks[trackSlug] || defaultTrackInfo
      return playlistWordCount + trackWordCount
    },
    0
  )

  if (wordCount !== updatedWordCount) {
    console.log(
      `out of sync playlist word counts for ${username}/${slug}: [${wordCount}/${updatedWordCount}]`
    )
    needsRealtimeSync = true
  }
  const playlist = {
    slug,
    title,
    owner: username,
    trackOrder: fixedUpTrackOrder,
    wordCount: updatedWordCount,
  }
  // So, we're fixing wordCount client side but will only update realtime playlist if isOwnedAllTracksPlaylist
  // TODO: is this reasonable?

  // TODO: !
  // dispatch(blasterPeersSlice.actions.updatePlaylist({ username, playlist }))

  if (needsRealtimeSync && isOwnedAllTracksPlaylist) {
    console.log('auto-syncing owned tracks')
    const updatedPlaylistDef = {
      ...playlist,
      timestamp: Date.now(),
    }
    const path = `matches/${username}/${ownedMatchSlug}/${slug}`
    realtime.set(path, updatedPlaylistDef)
  }
})

const updatePlaylist = createAsyncThunk<
  void,
  { matchSlug: string; playlistDef: PlaylistInfo; tracksToAdd: TrackInfo[] },
  { state: RootState; dispatch: AppDispatch }
>('updatePlaylist', ({ matchSlug, playlistDef, tracksToAdd = [] }, { dispatch, getState }) => {
  const {
    slug,
    title,
    wordCount = 0,
    timestamp = Date.now(),
    trackOrder = [],
    perTrackPlayLimit,
  } = playlistDef
  const state = getState()
  const matchOwner = selectCurrentUsername(state)
  const owningMatchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
  const { playlistOrder = [], playlists: prevPlaylists = {} } = owningMatchInfo
  const isNewPlaylist = !(slug in prevPlaylists)
  const updatedTrackOrder: string[] = [...trackOrder]
  tracksToAdd.forEach((newTrackInfo) => {
    dispatch(
      blasterPeersSlice.actions.updateTrack({
        username: matchOwner,
        trackInfo: { ...newTrackInfo },
      })
    )
    updatedTrackOrder.push(newTrackInfo.slug)
  })
  const updatedPlaylist: PlaylistInfo = {
    slug,
    title,
    wordCount, // TODO: recount here? optional param?
    timestamp,
    perTrackPlayLimit,
    trackOrder: [...new Set(updatedTrackOrder)], // make sure local state bugs never push dupes!
  }
  const updatedMatchInfo = {
    ...owningMatchInfo,
    playlistOrder: isNewPlaylist ? [slug, ...playlistOrder] : playlistOrder,
    playlists: {
      ...prevPlaylists,
      [slug]: updatedPlaylist,
    },
  }
  realtime.set(`matches/${matchOwner}/${matchSlug}/info`, updatedMatchInfo)
  dispatch(
    matchStatusSlice.actions.updateMatchInfo({
      matchOwner,
      matchSlug,
      matchInfo: updatedMatchInfo,
    })
  )
})

const updateTrack = createAsyncThunk<void, { trackInfo: TrackInfo }, { state: RootState }>(
  'updateTrack',
  ({ trackInfo }, { dispatch, getState }) => {
    const username = selectCurrentUsername(getState())
    const {
      slug,
      owner,
      timestamp = Date.now(),
      wordCount = 0,
      timedWordCount = 0,
      duration = 0,
    } = trackInfo
    const minimalTrackInfo = {
      slug,
      title: getSummaryTitle(trackInfo),
      owner,
      duration,
      wordCount,
      timedWordCount,
      timestamp,
    }
    realtime.set(`tracks/${username}/${slug}`, minimalTrackInfo)
  }
)

const removeTrack = createAsyncThunk<void, { slug: string }, { state: RootState }>(
  'updateTrack',
  ({ slug }, { dispatch, getState }) => {
    const username = selectCurrentUsername(getState())
    realtime.set(`tracks/${username}/${slug}`, null)
  }
)

export { initPlaylist, updatePlaylist, updateTrack, removeTrack }
