import cx from 'classnames'
import React, { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useState } from 'react'

import { Pane } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import {
  selectCurrentGamers,
  selectCurrentPlaylistSlug,
} from '../../selectors/current-play-selectors'
import { selectMatchInfo } from '../../selectors/match-selectors'
import {
  selectAudience,
  selectAudienceFlags,
  selectCurrentMatchSlug,
  selectCurrentUsername,
} from '../../selectors/session-selectors'
import keyBindings from '../../services/KeyBindings'
import { onModalContainerRef } from '../../util/scrolling'
import CloseIcon from '../widgets/CloseIcon'
import ModalBackdrop from './ModalBackdrop'
import { SelectWithLabel } from '../widgets/TextInputWithLabel'
import StatsByTrackTable from './leaderboard/StatsByTrackTable'
import StatsByPlayerTable from './leaderboard/StatsByPlayerTable'
import TrackGallery from './leaderboard/TrackGallery'
import VisibilitySetting from './leaderboard/VisibilitySetting'

type Props = {
  initialTab?: string
}
const audienceLabels = {
  pub: 'Pub',
  couch: 'Couch',
  solo: 'Solo',
  prod: 'Producer',
}
const tabRadio = [
  { name: 'Leaderboard', key: 'admin' },
  { name: 'Round Dashboard', key: 'round' },
  { name: 'Track Stats', key: 'match' },
  { name: 'Track Gallery', key: 'track' },
]

const LeaderboardModal = ({ initialTab = 'round' }: Props) => {
  const dispatch = useAppDispatch()
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.STATUS))
  const audience = useAppSelector(selectAudience)
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const currentPlaylistSlug = useAppSelector(selectCurrentPlaylistSlug)
  const {
    playlistOrder,
    playlists,
    title: matchTitle,
  } = useAppSelector(selectMatchInfo(matchOwner, matchSlug))
  const trackSetMenuItems = playlistOrder.map((playlistSlug) => {
    const { title } = playlists[playlistSlug]
    return { value: playlistSlug, name: title }
  })
  const isPubMode = useAppSelector(selectAudienceFlags).isPub
  const [whichTab, setWhichTab] = useState<string>(initialTab)
  const [whichTrackSet, setWhichTrackSet] = useState(currentPlaylistSlug)
  const gamers = useAppSelector(selectCurrentGamers).filter(({ isActive }) => {
    return isActive
  })

  // const className = cx(PaneClasses[Pane.STATUS], { hasFocus: isCurrFocus, left: true })
  // const onClick = () => {
  //   // console.log(`container: ${PaneClasses[Pane.SETTINGS]}`)
  //   dispatch(activateNextPane({ pane: Pane.STATUS }))
  // }
  const onClose = () => {
    keyBindings.popModal()
    dispatch(modalsSlice.actions.toggleLeaderboardModal({ isOpen: false }))
    // if (isWatching) {
    //   dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: false }))
    // }
  }
  const onSwitchTab = (tab: string) => {
    setWhichTab(tab)
  }
  const onSwitchTrackset = (event: ChangeEvent<HTMLSelectElement>) => {
    setWhichTrackSet(event.target.value)
  }
  const onKeyDown = (event: ReactKeyboardEvent<HTMLSelectElement>) => {
    // handle up / down arrows only
    const step = event.keyCode === 40 ? 1 : event.keyCode === 38 ? -1 : 0
    if (step !== 0) {
      const currTarget = event.currentTarget
      const nextIndex = Math.min(
        Math.max(0, currTarget.selectedIndex + step),
        currTarget.options.length - 1
      )
      const nextValue = currTarget.options[nextIndex].value
      currTarget.selectedIndex = nextIndex
      currTarget.value = nextValue
      setWhichTrackSet(nextValue)
      event.stopPropagation()
    }
  }

  const getBody = () => {
    if (whichTab === 'admin') {
      return (
        <div className="nutherWrapper">
          <StatsByPlayerTable matchOwner={matchOwner} matchSlug={matchSlug} playlistSlug="" />
        </div>
      )
    }
    if (whichTab === 'track') {
      return <TrackGallery playlistSlug={whichTrackSet} />
    }
    return (
      <div>
        {whichTab === 'round' && (
          <StatsByPlayerTable
            matchOwner={matchOwner}
            matchSlug={matchSlug}
            playlistSlug={whichTrackSet}
          />
        )}
        {whichTab === 'match' && <StatsByTrackTable matchSlug={currentMatchSlug} />}
      </div>
    )
  }
  const getRoundBanner = () => {
    const showBlasters = whichTab === 'track'
    const showBlaster = (blasterIndex: number) => {
      if (!showBlasters || blasterIndex >= gamers.length) {
        return null
      }
      const { playerName, isActive } = gamers[blasterIndex]
      const divClassname = cx('player', {
        left: blasterIndex === 0,
        right: blasterIndex === 1,
      })
      const buttonClassname = cx('blastButton', {
        mini: true,
        left: blasterIndex === 0,
        inactive: !isActive,
      })
      return (
        <div className={divClassname}>
          <div className="name">{playerName}</div>
           {/*<button className={buttonClassname} /> */}
        </div>
      )
    }
    return (
      <div className="round">
        {showBlaster(0)}
        <SelectWithLabel
          label=""
          itemArray={trackSetMenuItems}
          onChange={onSwitchTrackset}
          value={currentPlaylistSlug}
          onKeyDown={onKeyDown}
          isDisableTabIndex={isPubMode}
        />
        {showBlaster(1)}
      </div>
    )
  }
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer leaderboardModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">
          <div className="left">

            <div className="mode-selector">
              {tabRadio.map(({ name, key }, i) => (
                <button
                  className={cx('pushButton', { active: key === whichTab })}
                  key={key}
                  onClick={() => onSwitchTab(key)}
                >
                  {name}
                </button>
              ))}
            </div>


          </div>
          <div className="center">
            <div className="matchTitle">{matchTitle}</div>
            {(whichTab === 'round' || whichTab === 'track') && getRoundBanner()}
          </div>
          <div className="right">
            <VisibilitySetting />
            {/*<button className="blastButton" tabIndex={-1} />*/}
            {/*<button className="audience">{audienceLabels[audience]}</button>*/}
          </div>
        </div>
        {getBody()}
      </div>
    </ModalBackdrop>
  )
}

export default LeaderboardModal
