import React, { useEffect } from 'react'

import { duplicateMatch } from '../../../actions/authoring/duplicateMatch'
import { toggleLeaderboard } from '../../../actions/social/leaderboard-actions'
import loadTrack from '../../../actions/track-loading/loadTrack'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import modalsSlice from '../../../reducers/modalsSlice'
import { selectIsOwnedMatchSlug } from '../../../selectors/blaster-peer-selectors'
import { selectMatchStatus } from '../../../selectors/match-selectors'
import keyBindings from '../../../services/KeyBindings'
import { MatchStatus } from '../../../types'
import { onModalContainerRef } from '../../../util/scrolling'
import Util from '../../../util/util'
import CloseIcon from '../../widgets/CloseIcon'
import ModalBackdrop from '../ModalBackdrop'
import GuestPlayers from './GuestPlayers'
import MatchInfoSection from './MatchInfoSection'
import { MatchInvites } from './MatchInvites'
import { MatchTracks } from './MatchTracks'
import { selectMatchTracks } from '../../../selectors/match-selectors'

type Props = {
  currUserId: string
  matchOwner: string
  matchSlug: string
}

const MatchInfoModal = ({ currUserId, matchOwner, matchSlug }: Props) => {
  const dispatch = useAppDispatch()
  const matchStatus: MatchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const { info: matchInfo, invites: matchInvites, leaderboard, players } = matchStatus
  const { isShareable, isCopyable } = matchInfo || {}
  const isOwner = currUserId === matchOwner
  const [workingMatchSlug] = React.useState(matchSlug || Util.generateId())
  const compoundMatchSlug = `${matchOwner}/${workingMatchSlug}`
  const isOwnedMatch = useAppSelector(selectIsOwnedMatchSlug(compoundMatchSlug))
  const [isWatching, setIsWatching] = React.useState(false)
  const [isRunningPreload, setIsRunningPreload] = React.useState(false)
  const [isCancelPreload, setIsCancelPreload] = React.useState(false)
  const [preloadLabel, setPreloadLabel] = React.useState('')

  const matchTracksBySet = useAppSelector(selectMatchTracks(compoundMatchSlug))

  const onClose = () => {
    keyBindings.popModal()
    dispatch(modalsSlice.actions.toggleMatchInfo(''))
    if (isWatching) {
      dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: false }))
    }
  }
  useEffect(() => {
    if (matchSlug && !isWatching) {
      dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: true }))
      setIsWatching(true)
    }
  }, [dispatch, matchOwner, matchSlug, isWatching])
  const onDuplicate = () => {
    dispatch(duplicateMatch({ matchOwner, matchSlug }))
  }

  const onPreloadMatchAssets = () => {
    setIsCancelPreload(false)
    setIsRunningPreload(true)
    setPreloadLabel('')
    console.log(`pre-loading ${matchInfo.title}...`)
    setTimeout(async () => {
      let totalSecs = 0
      for (const { tracks, title } of matchTracksBySet) {
        let setSeconds = 0
        if (isCancelPreload) {
          break // TODO: this never gets called
        }
        for (const { slug, owner, title } of tracks) {
          if (isCancelPreload) {
            break // TODO: this never gets called cuz isCancelPreload already bound? FIX!
          }
          try {
            const startMillis = Date.now()
            await dispatch(loadTrack({ slug, playlistOwner: owner, forCache: true }))
            const elapsedSeconds = (Date.now() - startMillis) / 1000
            console.log(`${slug} ${title} files loaded in: ${elapsedSeconds}s`)
            setPreloadLabel(title)
            setSeconds += elapsedSeconds
          } catch (error: unknown) {
            console.log(`error pre-loading track ${slug}: ${title}`, error)
          }
        }
        console.log(`set ${title} pre-loaded in ${setSeconds}s`)
        totalSecs += setSeconds
      }
      console.log(`match ${matchInfo.title} pre-loaded in: ${totalSecs}s`)
      setPreloadLabel(`pre-loaded in ${Util.secondsToClock(totalSecs)}`)
      setIsRunningPreload(false)
    }, 50)
  }

  const Leaderboard = () => {
    const getLeaderboardRows = () => {
      return leaderboard.map((compoundPlayer, index) => {
        const [username, player] = compoundPlayer.split('/')
        const {
          matchScore: { topScore, topScoreRank },
          name,
        } = players[username][player]
        const isCurrUser = player === currUserId
        const playerClassName = `player${isCurrUser ? ' self' : ''}`
        return (
          <div key={`leaderboard-${index}`} className="row">
            <div className="rank">{index + 1}</div>
            <div className={playerClassName} title={player}>
              {name || player}
            </div>
            <div className={`score ${topScoreRank}2`}>{Util.numberWithCommas(topScore)}</div>
          </div>
        )
      })
    }
    const isLeaderboardEmpty = Object.keys(leaderboard).length === 0
    return (
      <>
        <label>Leaderboard:</label>
        <div className="leaderboard">
          {isLeaderboardEmpty ? <span>(no players yet)</span> : getLeaderboardRows()}
        </div>
      </>
    )
  }
  const title = `${isOwnedMatch ? 'My Collection' : 'Match'} Info`
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer matchInfoModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">{title}</div>
        <div className="twoCol">
          <div className="left">
            <MatchInfoSection
              currUserId={currUserId}
              matchOwner={matchOwner}
              matchSlug={workingMatchSlug}
              matchInfo={matchInfo}
              isOwnedMatch={isOwnedMatch}
            />
            {matchSlug && !isOwnedMatch && (
              <MatchInvites
                currUserId={currUserId}
                matchOwner={matchOwner}
                matchInvites={matchInvites}
                matchSlug={workingMatchSlug}
                isOwnedMatch={isOwnedMatch}
                isShareable={isShareable}
              />
            )}
            {matchSlug && isOwner && !isOwnedMatch && (
              <GuestPlayers compoundMatchSlug={compoundMatchSlug} />
            )}
            {matchSlug && !isOwner && <Leaderboard />}
          </div>
          <div className="right">
            {matchSlug && (
              <div>
                {isOwner && (
                  <MatchTracks
                    currUserId={currUserId}
                    matchOwner={matchOwner}
                    workingMatchSlug={workingMatchSlug}
                    matchInfo={matchInfo}
                    isOwnedMatch={isOwnedMatch}
                  />
                )}
                <div className="bottom">
                  {(isOwner || isCopyable) && (
                    <button onClick={onDuplicate}>Duplicate Match</button>
                  )}
                  <button onClick={onPreloadMatchAssets} disabled={isRunningPreload}>
                    {isRunningPreload ? 'Preloading...' : 'Preload'}
                  </button>
                  {isRunningPreload && (
                    <button
                      onClick={() => {
                        setIsCancelPreload(true)
                      }}
                    >
                      Cancel
                    </button>
                  )}
                  <span>{preloadLabel}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default MatchInfoModal
