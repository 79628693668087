import cx from 'classnames'
import React, { ChangeEvent } from 'react'
import parse from 'url-parse'

import {
  addTrackToLocalPlaylist,
  removeTrackFromLocalPlaylist,
  updateLocalTitle,
  updateLocalArtist,
  updateLocalLinks,
} from '../../actions/authoring/update-local-track'
import { audioUrlChanged } from '../../actions/authoring/loadLocalAudio'
import { adoptCurrentTrack } from '../../actions/authoring/upload-actions'
import spotifyLogo from '../../assets/img/spotify-badge.png'
import appleLogo from '../../assets/img/apple-badge.svg'
import {
  DEFAULT_PLAYLIST_KEY,
  DEFAULT_PLAYLIST_TITLE,
  MAX_URL_LENGTH,
} from '../../constants/constants'
import {
  selectIsCurrentTrackAdoptable,
  selectTrackInfo,
} from '../../selectors/blaster-peer-selectors'
import {
  selectCurrentTrackInfo,
  selectMatchInfoMapForOwnedTrack,
  selectPlaylistInfoMapForCurrentTrack,
} from '../../selectors/current-play-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectMode,
} from '../../selectors/session-selectors'
import CloseIcon from '../widgets/CloseIcon'
import {
  ControlledTextInputWithLabel,
  DivWithLabel,
  TextInputWithLabel,
} from '../widgets/TextInputWithLabel'
import PlaylistModal from './PlaylistModal'
import ModalBackdrop from './ModalBackdrop'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { MatchStatusMap, TrackActions } from '../../types'
import { imageUrlChanged } from '../../actions/authoring/loadLocalImage'
import { onModalContainerRef } from '../../util/scrolling'

type MusicService = 'apple' | 'spotify'
const logos = { apple: appleLogo, spotify: spotifyLogo }

type Props = {
  trackActions: TrackActions
  onClose: () => void
}
const TrackInfoModal = ({ trackActions, onClose }: Props) => {
  const { chooseLocalFile, chooseLocalImageFile } = trackActions
  const dispatch = useAppDispatch()
  const mode = useAppSelector(selectMode)
  const isEditMode = mode === 'edit'
  const isReadOnly = !isEditMode // TODO: support for other modes as long as track is local
  const trackInfo = useAppSelector(selectCurrentTrackInfo)
  const matchSlug = useAppSelector(selectCurrentMatchSlug)
  const playlistMap = useAppSelector(selectPlaylistInfoMapForCurrentTrack)
  const playlistSlugs = Object.keys(playlistMap)
  // TODO: move to selector
  const isTrackInAnyCustomPlaylist = playlistSlugs.some((playlistSlug) => {
    if (playlistSlug === DEFAULT_PLAYLIST_KEY) {
      return false // always added
    }
    const { containsCurrentTrack } = playlistMap[playlistSlug]
    return containsCurrentTrack
  })
  const localAudioPath = useAppSelector((state) => state.currentPlay.localAudioPath)
  const localImagePath2 = useAppSelector((state) => state.currentPlay.localImagePath)
  const {
    slug,
    title,
    artist,
    owner,
    links = '',
    remotePath: audioUrl,
    localPath,
    localImagePath,
    remoteImagePath: imageUrl,
    wordCount,
    timedWordCount,
  } = trackInfo
  const referencedMatchesMap: MatchStatusMap = useAppSelector(selectMatchInfoMapForOwnedTrack(slug))
  const username = useAppSelector(selectCurrentUsername)
  const isOwnedByCurrUser = !owner || owner === username
  const peerTrackInfo = useAppSelector(selectTrackInfo(username, slug))
  const isNew = isOwnedByCurrUser && !peerTrackInfo
  const [selectedPlaylistInfo, setSelectedPlaylistInfo] = React.useState<{
    slug: string
    title: string
  } | null>(null)
  const [trackTitle, setTrackTitle] = React.useState('')
  const [trackArtist, setTrackArtist] = React.useState('')
  const isAdoptable = useAppSelector(selectIsCurrentTrackAdoptable)
  const onClosePlaylistModal = () => {
    setSelectedPlaylistInfo(null)
  }
  const onTitleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newTitle = event.target.value
    dispatch(updateLocalTitle(slug, newTitle))
    setTrackTitle('')
  }
  const onArtistBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newArtist = event.target.value
    dispatch(updateLocalArtist(slug, newArtist))
    setTrackArtist('')
  }
  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newTitle = event.target.value
    setTrackTitle(newTitle)
  }
  const onArtistChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newArtist = event.target.value
    setTrackArtist(newArtist)
  }
  const onLinksChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    dispatch(updateLocalLinks(slug, event.target.value))
  }
  const onAudioURLChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newUrl = event.target.value
    if (newUrl && newUrl !== audioUrl) {
      dispatch(audioUrlChanged(newUrl))
    }
  }
  const onImageURLChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      return
    }
    const newUrl = event.target.value
    if (newUrl && newUrl !== imageUrl) {
      dispatch(imageUrlChanged(newUrl))
    }
  }
  const onTrackOwnerClick = () => {
    // realtimeManager.activatePeerInfo(this.trackOwner.innerText); // TODO
  }
  const getLocalAudioDiv = () => {
    const needFile = !audioUrl || !audioUrl.length
    const className = cx('localAudioWrapper', { hidden: !isEditMode, fileMissing: needFile })
    const onChooseFileClick = () => {
      chooseLocalFile()
    }
    return (
      <div className={className}>
        <label htmlFor="local-audio">Local Audio:</label>
        <input
          id="electron-local-audio"
          type="text"
          tabIndex={-1}
          readOnly
          value={localPath || localAudioPath}
        />
        <button onClick={onChooseFileClick}>Choose Audio...</button>
      </div>
    )
  }
  const getLocalImageDiv = () => {
    const className = cx('localImageWrapper', { hidden: !isEditMode })
    const onChooseFileClick = () => {
      chooseLocalImageFile()
    }
    return (
      <div className={className}>
        <label htmlFor="local-image">Local Image:</label>
        <input
          id="electron-local-image"
          type="text"
          tabIndex={-1}
          readOnly
          value={localImagePath || localImagePath2}
        />
        <button onClick={onChooseFileClick}>Choose Image...</button>
      </div>
    )
  }
  const getAudioLinksDisplay = () => {
    const linksArray = links.split(' ')
    return linksArray.map((linkText, i) => {
      if (!linkText) {
        return null
      }
      const url = parse(linkText)
      const hostParts = url.hostname.split('.')
      const linkName = hostParts.length <= 2 ? hostParts[0] : hostParts[1]
      const linkContents = () => {
        return linkName in logos ? (
          <img src={logos[linkName as MusicService]} alt="links" />
        ) : (
          linkName
        )
      }
      return (
        <div key={`tracklink-${i}`}>
          <a target="_blank" rel="noopener noreferrer" href={linkText}>
            {linkContents()}{' '}
          </a>
        </div>
      )
    })
  }
  const getLinks = () => {
    if (isReadOnly) {
      return (
        <DivWithLabel id="audioLinksDisplay" label="Links" className="linksBox">
          {getAudioLinksDisplay()}
        </DivWithLabel>
      )
    }
    return (
      <>
        <TextInputWithLabel
          maxLength={300}
          id="audioLinks"
          label="Links"
          value={links}
          onBlur={onLinksChanged}
        />
        <div id="audioLinksDisplay" className="linksBox">
          {getAudioLinksDisplay()}
        </div>
      </>
    )
  }
  const updatePlaylistMembership = (playlistSlug: string, isMember: boolean) => {
    if (isMember) {
      dispatch(
        addTrackToLocalPlaylist({ playlist: playlistMap[playlistSlug], tracksToAdd: [trackInfo] })
      )
    } else {
      dispatch(removeTrackFromLocalPlaylist({ playlistSlug, trackInfo }))
    }
  }
  const PlaylistItem = ({
    slug,
    title,
    isInitiallyChecked,
  }: {
    slug: string
    title: string
    isInitiallyChecked: boolean
  }) => {
    const [isChecked, setIsChecked] = React.useState(isInitiallyChecked)
    const checkboxId = `include-${slug}`
    const labelClassName = cx({ newPlaylist: !slug })
    const onLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
      setSelectedPlaylistInfo({ slug, title })
      event.preventDefault() // otherwise checkbox will get onChange, too!
    }
    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newIsChecked = event.currentTarget.checked
      updatePlaylistMembership(slug, newIsChecked)
      setIsChecked(newIsChecked)
    }
    const isDisableRemovalFromDefaultPlaylist =
      isTrackInAnyCustomPlaylist || isOwnedByCurrUser || isNew
    const disabled = !slug || (slug === DEFAULT_PLAYLIST_KEY && isDisableRemovalFromDefaultPlaylist)
    return (
      <div>
        <input
          id={checkboxId}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={onCheckboxChange}
        />
        <label htmlFor={checkboxId} className={labelClassName} onClick={onLabelClick}>
          {title}
        </label>
      </div>
    )
  }

  const getPlaylists = () => {
    const playlists = playlistSlugs.map(function (key) {
      if (key === DEFAULT_PLAYLIST_KEY) {
        return null // always added
      }
      const { title, containsCurrentTrack } = playlistMap[key]
      return (
        <PlaylistItem
          key={`playlist-${key}`}
          slug={key}
          title={title}
          isInitiallyChecked={!!containsCurrentTrack}
        />
      )
    })
    const newPlaylist = (
      <PlaylistItem
        key={'playlist-new'}
        slug={''}
        title={'New Playlist...'}
        isInitiallyChecked={false}
      />
    )
    const { title, containsCurrentTrack } = playlistMap[DEFAULT_PLAYLIST_KEY] || {
      title: DEFAULT_PLAYLIST_TITLE,
    }
    const isInitiallyChecked = containsCurrentTrack || isNew
    const defaultPlaylist = (
      <PlaylistItem
        key={'playlist-default'}
        slug={DEFAULT_PLAYLIST_KEY}
        title={title}
        isInitiallyChecked={isInitiallyChecked}
      />
    )
    return [newPlaylist, defaultPlaylist, ...playlists]
  }
  const getPlaylistSection = () => {
    const getMatches = () => {
      const matchItems = Object.keys(referencedMatchesMap).map((matchSlug) => {
        const {
          info: { slug, title },
        } = referencedMatchesMap[matchSlug]
        return <div key={slug}>{title}</div>
      })
      return matchItems
    }
    if (!isOwnedByCurrUser) {
      if (!playlistSlugs.length) {
        return null
      }
      return (
        <div>
          <label className="wide">Adopt To:</label>
          <div id="playlist-display-div" className="playlistDisplay">
            {getPlaylists()}
          </div>
        </div>
      )
    }
    return (
      <div>
        <label className="wide">Match Membership:</label>
        <div className="playlistDisplay matches">{getMatches()}</div>
      </div>
    )
  }
  const getPlaylistModal = () => {
    if (!selectedPlaylistInfo) {
      return false
    }
    const { slug, title } = selectedPlaylistInfo
    return (
      <PlaylistModal
        matchSlug={matchSlug}
        playlistSlug={slug}
        playlistTitle={title}
        currTrackInfo={trackInfo}
        onClose={onClosePlaylistModal}
      />
    )
  }
  const onAdoptClick = () => {
    dispatch(adoptCurrentTrack())
  }
  const containerClass = cx('modalContainer', 'trackInfoModal', { editable: !isReadOnly })
  const editorClass = cx('trackOwner', { self: isOwnedByCurrUser })
  return (
    <ModalBackdrop isTop>
      <div className={containerClass} ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} isTop={!selectedPlaylistInfo} />
        <div className="modalHeading">Track Info</div>
        <div>
          <label htmlFor="audioSlug">Blaster ID: </label>
          <input
            id="audioSlug"
            className="textLabel slug"
            type="text"
            readOnly
            defaultValue={slug}
          />
        </div>
        <div>
          <label htmlFor="trackOwner">Editor: </label>
          <span id="trackOwner" className={editorClass} onClick={onTrackOwnerClick}>
            {owner}
          </span>
          {isAdoptable && (
            <button className="adopt" onClick={onAdoptClick}>
              Adopt
            </button>
          )}
        </div>
        <ControlledTextInputWithLabel
          id="audioArtist"
          label="Artist"
          isReadOnly={isReadOnly}
          value={trackArtist || artist}
          onBlur={onArtistBlur}
          onChange={onArtistChanged}
        />
        <ControlledTextInputWithLabel
          id="audioTitle"
          label="Title"
          isReadOnly={isReadOnly}
          value={trackTitle || title}
          onBlur={onTitleBlur}
          onChange={onTitleChanged}
        />
        {!isReadOnly && (
          <TextInputWithLabel
            id="audioURL"
            label="Remote Audio"
            value={audioUrl}
            onBlur={onAudioURLChanged}
            maxLength={MAX_URL_LENGTH}
          />
        )}
        {getLocalAudioDiv()}
        {!isReadOnly && (
          <TextInputWithLabel
            id="imageURL"
            label="Remote Image"
            value={imageUrl}
            onBlur={onImageURLChanged}
            maxLength={MAX_URL_LENGTH}
          />
        )}
        {getLocalImageDiv()}
        {getLinks()}
        <div>
          <div className="multiValueRow">
            <label>Timing: </label>
            <input type="text" readOnly defaultValue={timedWordCount} />
            <span> / </span>
            <input type="text" readOnly defaultValue={wordCount} />
            <span> words </span>
          </div>
        </div>
        {getPlaylistSection()}
        {getPlaylistModal()}
      </div>
    </ModalBackdrop>
  )
}

export default TrackInfoModal
