import { createAsyncThunk } from '@reduxjs/toolkit'

import { Pane, PaneClasses } from '../constants/constants'
import { AppDispatch, RootState } from '../reducers'
import currentPlaySlice from '../reducers/currentPlaySlice'
import focusSlice from '../reducers/focusSlice'
import socialSlice from '../reducers/socialSlice'
import { selectCurrentSessionInfo } from '../selectors/session-selectors'
import {
  selectCurrentPlaylistSlug,
  selectCurrentTrackSlug,
} from '../selectors/current-play-selectors'
import keyBindings from '../services/KeyBindings'
import player from '../services/TrackMixer'
import { updateMatchStatus } from './social/leaderboard-actions'
import sessionSlice from '../reducers/sessionSlice'

const togglePlay = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'playAction/togglePlay',
  (_, { dispatch, getState }) => {
    if (player.isPlaying) {
      dispatch(currentPlaySlice.actions.setIsPlaying(false))
      player.stop()
    } else {
      const state = getState()
      const { currentBlaster, currentMatchSlug: matchSlug } = selectCurrentSessionInfo(state)
      const playlistSlug = selectCurrentPlaylistSlug(state)
      const trackSlug = selectCurrentTrackSlug(state)
      dispatch(activateNextPane({ pane: Pane.PLAY }))
      dispatch(
        socialSlice.actions.toggleTrackShowActiveScore({
          username: currentBlaster,
          matchSlug,
          playlistSlug,
          trackSlug,
          isShowActiveScore: true,
        })
      )
      if (player.isRewound && player.isPlayMode) {
        dispatch(sessionSlice.actions.setIsShowCountdownClock(true))
      } else {
        player.start()
        dispatch(currentPlaySlice.actions.setIsPlaying(true))
      }
    }
    dispatch(updateMatchStatus(-1))
  }
)

type NextPane = {
  direction?: -1 | 0 | 1
  pane?: Pane
}
const activateNextPane = createAsyncThunk<
  void,
  NextPane,
  { state: RootState; dispatch: AppDispatch }
>('playAction/activateNextPane', ({ direction = 0, pane }, { dispatch, getState }) => {
  const currFocus = getState().focus.currentFocus
  const numPanes = Object.keys(PaneClasses).length
  if (pane === currFocus) {
    return
  }
  const fromPane = pane !== undefined ? pane : currFocus
  let nextContainer = null
  let distance = 1
  while (!nextContainer && distance < numPanes) {
    const nextFocus: Pane = (fromPane + direction * distance++) % numPanes
    nextContainer = document.querySelector(`.${PaneClasses[nextFocus]}`)
    if (nextContainer) {
      keyBindings.onModalContainerRef(nextContainer, true)
      dispatch(focusSlice.actions.setFocus(nextFocus))
    }
  }
})

export { activateNextPane, togglePlay }
